/**======================================================================
=========================================================================
Template Name: Able Pro Admin Template
Author: Phoenixcoded
supports: https://phoenixcoded.ticksy.com
File: style.css
=========================================================================
=========================================================================
  == Table of Contents==
	- Generic classes
		-	Margin, Padding, Font class, text align, position, floating, overflow, background class, text color, display class, boarder class
	- Theme Elements
		-	Accordion, Button, tabs, typography, buttons, box shadow, Lable & Badges, Alert, Pagination, Breadcumb, Cards, Collapse,
        -   Carousel, Grid, Progress, Model, tooltip, popover, Datepicker, Gridstack, lightbox, notification, Nestable, pnotify, rating,
        -   Rangeslider, Slider, Syntax Highlighter, Tour, Treeview, Toolbar, Session Timeout, Session idle Timeout, offline, Animation
    - Forms
        -   Forms Elements, Advance Form Control, Validation, Masking, Wizard, Picker, Select
	- Pages
		-   authentication, Maintenance, Maps, Landing page messages, task, Todo, Notes, Charts, Icons, Gallery, Editors,
        -   Invoice, Full Calender, File Upload,
=================================================================================
=================================================================================== */


// Theme Font
@import "themes/font/theme-font";

// theme scrollbar
@import "themes/plugin/perfect-scrollbar";

// main framework
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "settings/custom-variables";
@import "settings/theme-variables";

// ng module
@import "react-module/react-module";

// bootstrap
@import "~bootstrap/scss/bootstrap";


// icon
@import "themes/font/next-icon";
@import "themes/font/feather";
@import "themes/font/fontawesome";
@import "themes/font/flaticon";
@import "themes/font/cryptofont";

// bootstrap css
@import "themes/bootstrap-overlay/card";
@import "themes/bootstrap-overlay/button";
@import "themes/bootstrap-overlay/radiobox-checkbox";
@import "themes/bootstrap-overlay/switches";
@import "themes/bootstrap-overlay/badge";

@import "themes/general";
@import "themes/generic";
@import "themes/plugin/animate";


// theme layouts
@import "themes/layouts/menu-lite";
@import "themes/layouts/menu-react";
@import "layout-dark";
@import "layout-rtl";

// Dashboard Widgets
@import "themes/dashboard/widget";

// custom plugin
@import "themes/plugin/sweatalert";
@import "themes/plugin/lightbox";
@import "themes/plugin/notification";
@import "themes/plugin/pnotify";
@import "themes/plugin/bar-rating";
@import "themes/plugin/rangeslider";
@import "themes/plugin/daterange";
@import "themes/plugin/tags-input";
@import "themes/plugin/maxlength";
@import "themes/plugin/wizard";
@import "themes/plugin/select2";
@import "themes/plugin/data-tables";

// Charts
@import "themes/plugin/highcharts";
@import "themes/plugin/peitychart";


// pages
@import "themes/pages/authentication";
@import "themes/pages/maintaince";
@import "themes/pages/pages";
@import "themes/pages/help-desk";
@import "themes/pages/fullcalender";
@import "themes/pages/icon-lauouts";
@import "themes/pages/user";
@import "themes/pages/task-board";
@import "themes/pages/masonry-gallery";

// custom
@import "themes/custom";

.dropdown-toggle::after {
  display: none !important
}

.modal-sm {
  max-width: 600px !important
}

#button-tooltip>.tooltip-inner {
  background-color: #fff;
  color: #000;
  border: 1px solid #062e56;
}

#button-tooltip>.tooltip-arrow {
  border-top: 5px solid #062e56;
}

.center {
  align-content: center;
  justify-content: center;
  align-self: center;
  text-align: center;
}

.rc-slider-tooltip-hidden {
  display: block !important;
  z-index: 100000000 !important;
}

// cevra-logo 
.container-img {
  height: 200px;
  text-align: center;
}

.container-img img {
  height: 200px;
}

div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  display: inline-block;
  width: 100%;
}

// mobile view
@media (max-width:767px) {
  .register-h1 {
    font-size: 25px;
  }
}


// fade effect
#cevra-logo {
  -webkit-animation: fadein 2s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s;
  /* Firefox < 16 */
  -ms-animation: fadein 2s;
  /* Internet Explorer */
  -o-animation: fadein 2s;
  /* Opera < 12.1 */
  animation: fadein 5s;
}

// cevra-device
#cevra-device {
  -webkit-animation: deviceFade 2s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: deviceFade 2s;
  /* Firefox < 16 */
  -ms-animation: deviceFade 2s;
  /* Internet Explorer */
  -o-animation: deviceFade 2s;
  /* Opera < 12.1 */
  animation: deviceFade 2s infinite;
  // animation-delay: 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  animation: fadein 2s;
}

@keyframes deviceFade {
  0% {
    opacity: 0.8;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.8;
  }
}

#L-text .auth-h2,
.auth-h5 {
  animation: faderight 2s;
  white-space: nowrap;
}

.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #3b6fff 0%,
    #a32f38 29%,
    #007483 67%,
    #3f3f3f 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 10s linear infinite;
  display: inline-block;
      font-size: 16px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
  50% {
    opacity: 0.8;
  }
  100%{
    opacity: 1;
  }
}

// left to right
@keyframes faderight {
  from {
    width: 0%;
    opacity: -1;
  }

  to {
    width: 100%;
    opacity: 1;
  }
}

#L-text hr {
  animation: faderight-hr 2s;
}

@keyframes faderight-hr {
  from {
    width: 0px;
    opacity: -1;
  }

  to {
    width: 600px;
    opacity: 1;
  }
}

// language selector
.select__control {
  border: none;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .lngSelect {
    width: 17% !important;
  }

  .h-img {
    width: 80px !important;
    height: 80px !important;
  }

  .col-9 h4 {
    font-size: 25px;
  }

  .header-dp {
    width: 50px;
  }
}

@media only screen and (min-device-width: 501px) and (max-device-width: 767px) {
  .lngSelect {
    width: 23% !important;
    right: 14pc !important;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width:500px) {
  .lngSelect {
    width: 35% !important;
    right: 14pc !important;
  }

  .h-img {
    width: 50px !important;
    height: 50px !important;
  }

  .col-9 h4 {
    font-size: 15px;
  }

  #drop-item {
    width: 50% !important;
  }

  .f-size {
    font-size: 11px !important;
  }

  .footer-font {
    font-size: 10px;
  }

  .for-mobile {
    display: block !important;
  }
}

// for client
.header-blue {
  background: linear-gradient(280deg, #ff8e54, #4680ff);
}

// for vendor
.background-purple {
  background: linear-gradient(to right, #5ea5fe, #c4bcbc, #728bb8) !important;
}

@media only screen and (max-width: 991px) {

  .pcoded-header>.collapse:not(.show),
  .pcoded-header .container>.collapse:not(.show) {
    background: none !important;
    box-shadow: none !important;
  }

  .pcoded-header.header-blue a {
    color: white !important;
  }
}

.errorMsg {
  color: #ff5252;
  float: left;
}


// news page img

#fileDrop {
  opacity: 0.0;

  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  /* IE 5-7 */
  filter: alpha(opacity=0);

  /* Netscape or and older firefox browsers */
  -moz-opacity: 0.0;

  /* older Safari browsers */
  -khtml-opacity: 0.0;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

#imgUP {
  height: 270px;
  border-radius: 8px;
}

#newsImg {
  height: 370px;
  border-radius: 10px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  #imgUP {
    height: 200px;
  }

  #newsImg {
    height: 330px;
    border-radius: 10px;
  }
}

@media only screen and (min-device-width: 501px) and (max-device-width: 767px) {
  #newsImg {
    height: 240px;
  }

  .location-td {
    width: 80% !important;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 500px) {
  #imgUP {
    height: 150px;
  }

  #newsImg {
    height: 170px;
    border-radius: 10px;
  }

  .location-td {
    width: 100% !important;
  }

  .guide {
    margin-bottom: 20px;
  }
}


th {
  background-color: #ecf3ff !important;
  // color: white !important;
}

// modal header 
.custom-header {
  background: linear-gradient(280deg, #ff8e54, #4680ff);
}

// card background
.card-back {
  background: linear-gradient(140deg, #fff2ed, #e4edff);
}

// opacity light
.div-op {
  opacity: 0.4;
}

// jQuery table head
.f-size th {
  font-size: 14px !important;
}

// background colors
.a {
  background-color: #9d60d4;
}

.b {
  background-color: #60d4ce;
}

.f-size {
  font-size: 12px;
}

// icon height width table
.sm-ico {
  height: 35px !important;
  width: 34px !important;
  border: none
}

.sm-ico:hover {
  background-color: #3d3d3d;
}

.dropdown .btn-primary {
  color: #4b80fa !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

@media only screen and (min-device-width: 1000px) {
  #ico {
    margin-left: 30px;
  }

  .footer-font {
    font-size: 16px;
  }
}

@media only screen and (min-device-width: 1000px) and (max-device-width: 1300px) {
  .lngSelect {
    width: 18% !important;
    right: 9pc !important;
  }
}

@media only screen and (max-device-width: 500px) {
  .pd {
    padding-left: 10px !important;
    padding-right: 0px !important;
  }

  .pl {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.green {
  color: rgb(48, 216, 48);
}

.red {
  color: rgb(255, 62, 62);
}

.sk-blue {
  color: rgb(43, 126, 126);
}

.bold {
  font-weight: bolder;
}

// jQuery table location class
.location-td {
  white-space: pre-wrap !important;
  width: 40%;
}

// .service-table tr:nth-child(3n+3) td:nth-child(4) {
//   white-space:pre-wrap !important;
//   width: 40%;
// }

.auth {
  align-self: center;
}

// button add request gradient
.btn-g:hover {
  border: 1px solid white;
  box-shadow: 2px 2px 20px rgb(173, 173, 173);
}

@media only screen and (min-device-width: 1000px) {
  .f-size {
    font-size: 12px !important;
  }

  .bell {
    font-size: 20px !important;
  }

  .for-mobile {
    display: none !important;
  }
}

// radio button

.radio-btn {
  display: inline-flex;
  height: 60px;
  width: 210px;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px 10px;
}

.radio-btn .option {
  background: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 10px;
  border: 2px solid lightgrey;
  transition: all 0.3s ease;
}

.radio-btn .option .dot {
  height: 15px;
  width: 15px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
}

.radio-btn .option .dot::before {
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 7px;
  height: 7px;
  background: #0069d9;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}

input[type="radio"] {
  display: none;
}

#option-1:checked:checked~.option-1,
#option-2:checked:checked~.option-2 {
  border-color: #2066ff;
  background: #2066ff;
}

#option-1:checked:checked~.option-1 .dot,
#option-2:checked:checked~.option-2 .dot {
  background: #fff;
}

#option-1:checked:checked~.option-1 .dot::before,
#option-2:checked:checked~.option-2 .dot::before {
  opacity: 1;
  transform: scale(1);
}

.radio-btn .option span {
  font-size: 14px;
  color: #808080;
}

#option-1:checked:checked~.option-1 span,
#option-2:checked:checked~.option-2 span {
  color: #fff;
}

.blue {
  color: #4680ff;
}

.pointer {
  cursor: pointer;
}

// footer 
.footer-distributed {
  background-color: #292c2f;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  font: normal 16px sans-serif;
  padding: 45px 50px;
}

.footer-distributed .footer-left p {
  color: #8f9296;
  font-size: 14px;
  margin: 0;
}

/* Footer links */

.footer-distributed p.footer-links {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  margin: 0 0 10px;
  padding: 0;
  transition: ease .25s;
}

.footer-distributed p.footer-links a {
  display: inline-block;
  line-height: 1.8;
  text-decoration: none;
  color: inherit;
  transition: ease .25s;
}

.footer-distributed .footer-links a:before {
  content: "·";
  font-size: 20px;
  left: 0;
  color: #fff;
  display: inline-block;
  padding-right: 5px;
}

.footer-distributed .footer-links .link-1:before {
  content: none;
}

.footer-distributed .footer-right {
  float: right;
  margin-top: 6px;
  max-width: 180px;
}

.footer-distributed .footer-right a {
  display: inline-block;
  width: 35px;
  height: 35px;
  background-color: #33383b;
  border-radius: 2px;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  line-height: 35px;
  margin-left: 3px;
  transition: all .25s;
}

.footer-distributed .footer-right a:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
}

.footer-distributed p.footer-links a:hover {
  text-decoration: underline;
}

/* Media Queries */

@media (max-width: 600px) {

  .footer-distributed .footer-left,
  .footer-distributed .footer-right {
    text-align: center;
  }

  .footer-distributed .footer-right {
    float: none;
    margin: 0 auto 20px;
  }

  .footer-distributed .footer-left p.footer-links {
    line-height: 1.8;
  }
}

.footer {
  // background:transparent;
  border: none;
}

// rating
.star-fill {
  color: #ffb036;
}

.star-blank {
  color: bisque;
}

.badge-orange {
  color: white;
  background-color: #ff8a46;
}

// blink background
.blink-text {
  color: #000;
  font-weight: bold;
  animation: blinkText 1.5s infinite;
  animation-delay: 100ms;
  border-radius: 5px;
}

@keyframes blinkText {
  0% {
    color: #000;
    background-color: #fff;
  }

  50% {
    color: rgb(63, 63, 63);
    background-color: rgb(255, 233, 233);
  }

  100% {
    color: #000;
    background-color: #fff;
  }
}

@media only screen and (max-device-width: 700px) {
  .cevra-footer {
    display: none;
  }
}

//scrolling cards
.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  // flex-wrap: wrap;
  .card {
    display: inline-block;
  }
}