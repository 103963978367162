.tab-user-card {
  .nav-pills {
    background: #ecf0f5;
    padding: 20px;
    border-radius: 5px;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.active{
  background: $primary-color;
  border-color: $primary-color;
}



.center div{
  justify-content: center;
}

.border-feild{
  padding-bottom: 10px;
  margin-top: 11px;
  }
  
  .border-in{
  border: 1px solid #c2c2c2;
  border-radius: 5px;
  padding: 5px !important;
  }

table.dataTable thead .sorting_asc{
  background-image: none !important;

}
table.dataTable thead .sorting_desc{
  background-image: none !important;
}
table.dataTable thead .sorting{
  background-image: none !important;
}